import { rentEventsRef, rentsRef } from '@/services/firebase';
import { compareDescPredicate } from '@/utils';
import { RentEntity } from '@/utils/rent';
import { fromDuration } from '@shveitsar/toolbox';
import { doc, DocumentReference, DocumentSnapshot, onSnapshot, orderBy, Query, query, QuerySnapshot, where } from 'firebase/firestore';
import { BehaviorSubject, combineLatest, interval, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
export * from './auth';
function collectionData(query) {
    const querySnapshots = new Observable((subscriber) => {
        const unsubscribe = onSnapshot(query, subscriber);
        return { unsubscribe };
    });
    return querySnapshots.pipe(map((querySnap) => querySnap.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data()
    }))));
}
function documentData(docref) {
    const docSnapshots = new Observable((subscriber) => {
        const unsubscribe = onSnapshot(docref, subscriber);
        return { unsubscribe };
    });
    return docSnapshots.pipe(map((docSnap) => docSnap.exists()
        ? {
            id: docSnap.id,
            ...docSnap.data()
        }
        : null));
}
export const now$ = interval(fromDuration({ seconds: 20 })).pipe(map(() => Date.now()), startWith(Date.now()), shareReplay(1));
export const route$ = new BehaviorSubject({
    fullPath: '/',
    hash: '',
    matched: [],
    meta: {},
    name: null,
    params: {},
    path: '/',
    query: {}
});
export const agencyId$ = route$.pipe(map((route) => (route.params['agencyId'] || null)), distinctUntilChanged(), shareReplay(1));
export const tenantId$ = route$.pipe(map((route) => (route.params['tenantId'] || null)), distinctUntilChanged(), shareReplay(1));
export const rentId$ = route$.pipe(map((route) => (route.params['rentId'] || null)), distinctUntilChanged(), shareReplay(1));
export const rents$ = combineLatest([
    agencyId$,
    tenantId$
]).pipe(switchMap(([agencyId, tenantId]) => agencyId
    ? collectionData(query(rentsRef, where('agencyId', '==', agencyId), where('phase', '!=', 'finished')))
    : tenantId
        ? collectionData(query(rentsRef, where('common.tenant.id', '==', tenantId), where('phase', '!=', 'finished')))
        : of(null)), map((value) => value &&
    value.map((valueItem) => RentEntity.fromFirestoreValue(valueItem))), map((value) => value &&
    value.sort((a, b) => compareDescPredicate(a.data.common.finishAt.toMillis(), b.data.common.finishAt.toMillis()))), shareReplay(1));
export const finishedRents$ = combineLatest([agencyId$, tenantId$]).pipe(switchMap(([agencyId, tenantId]) => agencyId
    ? collectionData(query(rentsRef, where('agencyId', '==', agencyId), where('phase', '==', 'finished'), orderBy('common.startAt', 'desc')))
    : tenantId
        ? collectionData(query(rentsRef, where('common.tenant.id', '==', tenantId), where('phase', '==', 'finished'), orderBy('common.startAt', 'desc')))
        : of(null)), map((value) => value &&
    value.map((valueItem) => RentEntity.fromFirestoreValue(valueItem))), map((value) => value &&
    value.sort((a, b) => compareDescPredicate(a.data.common.finishAt.toMillis(), b.data.common.finishAt.toMillis()))), shareReplay(1));
export const currentRent$ = rentId$.pipe(switchMap((rentId) => {
    if (!rentId) {
        return of(null);
    }
    return documentData(doc(rentsRef, rentId)).pipe(map((result) => result && RentEntity.fromFirestoreValue(result)));
}), shareReplay(1));
export const currentRentEvents$ = currentRent$.pipe(switchMap((currentRent) => currentRent
    ? collectionData(query(rentEventsRef(currentRent.id), orderBy('at')))
    : of([])), shareReplay(1));
